<script setup>
import {onMounted, ref} from 'vue';
import i18next from 'i18next';
import RedirectLogo from "@/components/RedirectLogo.vue";

onMounted(() => {
  setTimeout(() => {
    const redirectUrl = i18next.t('url.adeo.claim');
    window.location.href = redirectUrl;
  }, 3000);
});
</script>

<template>
  <div class="container">
    <RedirectLogo/>
    <div class="text">
      <h1>{{ $t('redirectMessage') }}</h1>
      <h3>{{ $t('to') }} <a :href="$t('url.adeo.claim')">https://www.adeo.com/</a></h3>
    </div>
  </div>
</template>
<style scoped lang="scss">
@import 'settings-tools/_all-settings';

.container {
  margin: auto;
  padding-bottom: 3vh;
}
.text {
  color : #ffffff;
}
a:visited {
  color: #ffffff;
}
</style>

