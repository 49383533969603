<script>

import {HOMEPAGE_LOGOS} from "@/constants/header";
import {getPath} from "@/utils/image";

export default {

  methods: {
    HOMEPAGE_LOGOS() {
      return HOMEPAGE_LOGOS;
    },
    getPath,
    displayDocumentSearch() {
      this.$router.push({name : 'documents'});
    },
    displayIncidents() {
      this.$router.push({name : 'productIncidents'});
    },
    displayClaim() {
      this.$router.push({name : 'claim'});
    }
  }
}
</script>

<template>
  <div class="l-choices" v-if="!displayChoices">
    <div class="l-choices__container">
      <h1>{{ $t('title') }}</h1>
      <div class="l-choices__description">
        <p>
          <span>{{ $t('description1') }}<b>{{ $t('description2') }}</b></span>
          <br/>
          <span>{{ $t('claimReportIncidentText') }}</span>
          <br/>
          <span hidden="hidden">{{ $t('authoritiesText') }}</span><!-- MAKE THIS TEXT VISIBLE WHEN AUTHORITIES BUTTON IS SET -->
        </p>
        <p><b>{{ $t('youNeed') }}</b></p>
      </div>
      <div class="l-choices__buttons">
        <MButton :label="$t('choices.buttons.documents')" @click="displayDocumentSearch" id="documents" icon="ArrowArrowRight24" icon-position="right" width="fit" size="s"/>
        <MButton :label="$t('choices.buttons.claim')" @click="displayClaim" id="claim" icon="ArrowArrowRight24" icon-position="right" size="s"/>
        <MButton :label="$t('choices.buttons.incidents')" @click="displayIncidents" id="incidents" icon="ArrowArrowRight24" icon-position="right" size="s"/>
      </div>
    </div>
    <div class="l-choices__brands">
      <h3>{{ $t('brands') }}</h3>
      <ul>
        <li v-for="logo in HOMEPAGE_LOGOS()" :key="logo.name">
          <img :src="getPath({ name: logo.name, extension: 'png' })" :alt="logo.alt" />
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'settings-tools/_all-settings';

.l-choices {
  font-family: 'Roboto', sans-serif;
  max-width: 1040px;
  margin: 0 auto;

  &__container {
    margin: 0 $mu100;
    background-color: white;
    padding: $mu100;
    border-radius: 8px;
    color: $color-grey-900;
    @include set-from-screen('m') {
      padding: $mu250 $mu400;
      max-width: fit-content;
      margin-inline: auto;
      margin: 0;
    }
  }

  h1 {
    @include set-font-scale('09', 'm');
    margin-bottom: $mu100;
  }
  &__description {
    @include set-font-scale('06', 'm');
    margin-bottom: $mu100;
    text-align: justify;
  }

  &__buttons {
    display: grid;
    @include set-from-screen('l') {
      justify-content: space-between;
      display: flex;
    }
    .mc-button {
      margin-bottom: $mu100;
      padding-top: $mu050;
      padding-bottom: $mu050;
    }

    #claim {
      background-color: #4B4A8C;
    }
    #incidents {
      background-color: #264653;
    }
    #documents {
      background-color: #46A610;
    }
  }

  &__brands {
    color: white;
    margin: 5% 0;
    justify-items: center;

    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-inline-start: 0;

      li {
        flex: 0 0 35%;
        @include set-from-screen('m') {
          flex: 0 0 10%;
        }
        background-color: white;
        margin: 2%;
        padding: 2%;
        border-radius: 4%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>