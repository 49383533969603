<template>
  <div class="form-container">
    <div class="form">
      <div class="form-row">
        <MField
          :label="$t('form.name.label')"
          id="name"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.name" :isInvalid="formSubmitted && !formData.name" :placeholder="$t('form.name.placeholder')" />
        </MField>
        <MField
          :label="$t('form.firstName.label')"
          id="firstName"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.firstName" :isInvalid="formSubmitted && !formData.firstName" :placeholder="$t('form.firstName.placeholder')" />
        </MField>
      </div>

      <MField
        :label="$t('form.country.label')"
        id="country"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextInput v-model="formData.country" :isInvalid="formSubmitted && !formData.country" :placeholder="$t('form.country.placeholder')" />
      </MField>

      <MField
        :label="$t('form.email.label')"
        id="email"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextInput v-model="formData.email" :isInvalid="formSubmitted && !formData.email" :placeholder="$t('form.email.placeholder')" />
      </MField>

      <MField
        :label="$t('form.description.label')"
        id="description"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextArea v-model="formData.description" :isInvalid="formSubmitted && !formData.description" :rows="4" :label="$t('form.description.placeholder')"/>
      </MField>

      <MField :id="'fileUpload'" class="formFileUpload">
        <MFileUploader
         
          ref="fileUploader"
          :label="$t('form.fileUpload.label')"
          v-model="formData.fileUpload"
          id="fileUpload"
          @file-added="onAddedFiles"
          @file-removed="removeFile"
        />
      </MField>

      <MCheckbox
        id="checkbox"
        v-model="formData.agree"
        :isInvalid="formSubmitted && !formData.agree"
      >
        <template #label class="checkbox-text">
          <p>{{ $t('form.checkbox.description') }}</p>
          <MLink
              v-bind="{ router: { name: 'router-link', props: { to: { name: 'privacy' } } } }"
          >
            {{ $t('form.checkbox.linkName') }}
          </MLink>
        </template>
      </MCheckbox>

      <MButton :label="$t('form.submitButton')"  @click="handleSubmit" />
    </div>
    <MNotification v-if="formError" type="danger" class="notification">
          <p>
            {{ $t('failedFormSubmitNotificationMessage') }}
          </p>
    </MNotification>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { CustomerRequestService } from '@/service/CustomerRequestService';
import MFileUploader from '@mozaic-ds/vue-3/src/components/fileuploader/MFileUploader.vue';

export default {
  emits: ['form-submitted'],

  setup(props, {emit}) {
    const store = useStore();
    const product = computed(() => store.getters.productDetails || {});

    const formData = reactive({
      name: '',
      firstName: '',
      country: '',
      email: '',
      description: '',
      fileUpload: null,
      agree: false,
    });

    const formSubmitted = ref(false);
    const formError = ref(false);

    function onAddedFiles(files) {
      if (files.length > 0) {
        formData.fileUpload = files[0];
      }
    }

    function removeFile() {
      formData.fileUpload = null;
    }

    async function handleSubmit() {
      formSubmitted.value = true;
      if (
          formData.name &&
          formData.firstName &&
          formData.country &&
          formData.email &&
          formData.description &&
          formData.agree
      ) {
        try {
          const success = await CustomerRequestService.incidentReportRequest(formData, product.value, formData.fileUpload);

          if (success) {
            formError.value = false;
            emit('form-submitted');
          } else {
            formError.value = true;
            throw new Error("Failed to submit form");
          }
        } catch (error) {
          formError.value = true;
        }
      }
    };

    return {
      product,
      formError,
      formSubmitted,
      formData,
      handleSubmit,
      onAddedFiles,
      removeFile
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: #ffffff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1008px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left !important;

}
.form-field div{
  min-width: 100%;

}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 20px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) {
  .form-container {
    padding: 40px 60px;
  }
}

.mc-checkbox__label p {
  font-weight: bold!important;
}
.mc-checkbox__label a {
  font-weight: bold!important;
}
.notification {
  margin-top: 20px;
}

.mc-fileuploader__label {
    width: 100%!important;
}

</style>
