<script setup>
import { computed, defineEmits, reactive, ref } from 'vue';
import Card from '@/components/Card.vue';
import { MPagination } from '@mozaic-ds/vue-3';
import { t } from 'i18next';
import store from '@/store';
import ProductDetailsLayer from '@/components/ProductDetailsLayer.vue';
import { FileService } from '@/service/FileService';

let message = '';
const fileService = FileService;

const props = defineProps({
  customSearchAction: {
    type: Function,
    default: null,
  }
});
const paginate = (value) => {
  store.dispatch('searchProducts', { keyWord: store.getters.searchKeyWord, from: value });
};

const handleCardClick = (product) => {
  if (props.customSearchAction) {
      store.commit('setProductDetails', product);
      props.customSearchAction();
  } else {
    openProductDetailsLayer(product);
  }
};

const openProductDetailsLayer = (product) => {
  store.dispatch('displayLayerProductDetails', true);
  store.dispatch('getProductDetails', { ...product, photoUrl: product.photoUrl });
};

const productsPage = computed(() => {
  return store.getters.products;
});

const isSearchInProgress = computed(() => {
  return store.getters.searchLoading;
});

const isProductHaveContent = computed(() => {
  return store.getters.products.content;
});

const searchKeyWord = computed(() => {
  return store.getters.searchKeyWord;
});

const getFiles = async (filesToDownload) => {
  if (filesToDownload.length === 1) {
    await downloadFile(filesToDownload[0]);
  } else if (filesToDownload.length > 1) {
    const filesIds = filesToDownload.map(file => file.id).join();
    await downloadFiles(filesIds);
  }
};

const downloadFile = async (file) => {
    const response = await fileService.downloadFile(file.id);
    const data = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = `${file.fileNameToDisplay}.${file.fileExtension}`;
    link.click();
    URL.revokeObjectURL(link.href);
};

const downloadFiles = async (filesIds) => {
    const response = await fileService.downloadZipFile(filesIds);
    const data = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = "zip_documents.zip";
    link.click();
    URL.revokeObjectURL(link.href);
};

message = computed(() => {
  const productsFind = store.getters.products.totalElements;
  return `${productsFind} ${t('documentsSearch.resultsMessage')} ${searchKeyWord.value}`;
});
</script>

<template>
  <section class="l-search-result">
    <div class="l-search-result__loader" v-if="isSearchInProgress">
      <MLoader v-bind="{ size: 'l' }" />
    </div>
    <div class="l-search-result__details" v-if="isProductHaveContent">
      <p class="l-search-result__description" v-if="!isSearchInProgress">
        {{ message }}
      </p>
      <ul class="l-search-result__list ml-flexy" v-if="!isSearchInProgress">
        <li
          class="l-search-result__item ml-flexy__col ml-flexy__col--full ml-flexy__col--initial@from-m"
          v-for="product in productsPage.content"
        >
          <Card :product="product" @click="handleCardClick(product)" />
        </li>
      </ul>
      <div
        class="l-search-result__pagination"
        v-if="isProductHaveContent && store.getters.products.totalElements > 0"
      >
        <MPagination
          :value="productsPage.pageable.pageNumber + 1"
          :length="productsPage.totalPages"
          :pageLabel="'of'"
          @on-update-page="paginate"
        ></MPagination>
      </div>
    </div>
  </section>

  <ProductDetailsLayer @download-files="getFiles"></ProductDetailsLayer>
</template>

<style lang="scss">
  @import 'settings-tools/_all-settings';
  @import 'layouts/l.container';
  @import 'layouts/l.flexy';

  .l-search-result {
    font-family: 'Roboto', sans-serif;
    background-color: #0d2c44;
    padding: 0 $mu100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__loader {
      flex: 0 1 100%;
    }

    &__description {
      @include set-font-scale('04', 'm');
      color: white;
      flex: 0 1 100%;
    }

    &__list {
      padding: 0;
      max-width: 1040px;
    }

    &__item {
      margin: $mu100 0;
      width: 228px;
      @include set-from-screen('l') {
        margin: $mu100;
      }
    }

    &__pagination {
      margin-bottom: $mu100;
      flex: 0 1 100%;

      .mc-button--solid-neutral:not([disabled]) {
        background-color: white !important;

        svg {
          fill: $color-grey-900;
        }
      }
    }
  }
</style>
