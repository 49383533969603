<script>
import Homepage from "@/components/Homepage.vue";
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
    Footer,
    Homepage
  }
}
</script>

<template>
  <div class="page-container">
    <Header />
    <main class="main-content">
      <Homepage />
    </main>
    <Footer />
  </div>
</template>

<style lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}
</style>
