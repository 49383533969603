<template>
  <div class="product">
    <MHeading v-bind="{ size: 's', weight: 'semi-bold' }">{{
        customTitle ? $t(customTitle) : $t('missingDocumentPageLabel')
      }}</MHeading>
    <div class="card-wrapper">
      <div :id="'product' + product.id" :class="['card', layoutClassCss]">
        <div class="card__mask" v-if="isLoadingPhoto">
          <div class="card__mask__photo">
            <div class="card__mask__photo__loader">
              <MLoader v-bind="{ size: 'm' }"/>
            </div>
          </div>
        </div>
        <div class="mc-card">
          <div class="mc-card__visual">
            <img :src="productPhotoUrl" :alt="product.name" height="165"/>
          </div>
          <div class="mc-card__content">
            <h6 class="mc-card__title">{{ product.name }}</h6>
            <div class="mc-card__body">
              <p class="mc-card__body__carac">
                {{ $t('GTIN') }}
                <span v-for="(gtin, index) in product.gtins" :key="index">
                {{ gtin }}<span v-if="index < product.gtins.length - 1">, </span>
              </span>
              </p>
              <p class="mc-card__body__carac">{{ $t('ADEOKEY') }} {{ product.adeoKey }}</p>
              <p class="mc-card__body__carac">
                {{ $t('REFERENCE') }}
                <span v-for="(reference, index) in product.references" :key="index">
                  {{ reference }}<span v-if="index < product.references.length - 1">, </span>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {MHeading, MLoader} from "@mozaic-ds/vue-3";
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import PhotoService from "@/service/PhotoService";
import {ProductDetailsService} from '@/service/ProductDetailsService.js';

const props = defineProps({
  customTitle: {
    type: String,
    default: null,
  }
})
const store = useStore();
const product = computed(() => store.getters.productDetails || {});
const productPhotoUrl = ref('');
const isLoadingPhoto = ref(false);

const photoService = new PhotoService();

const layoutClassCss = computed(() => {
  return window.innerWidth <= 680 ? 'vertical' : 'horizontal';
});

onMounted(async () => {
  isLoadingPhoto.value = true;
  try {
    if (product.value.photoUrl) {
      productPhotoUrl.value = photoService.getImagePath(product.value.photoUrl);
    } else if (product.value.id) {
      const photoUrl = await ProductDetailsService.getProductPhotoUrl(product.value.id)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text();
          })
          .catch(() => {
            return '';
          });
      productPhotoUrl.value = photoService.getImagePath(photoUrl);
    } else {
      productPhotoUrl.value = photoService.getImagePath('');
    }
  } catch (error) {
    productPhotoUrl.value = '';
  } finally {
    isLoadingPhoto.value = false;
  }
});
</script>


<style scoped>
.product {
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 10px;
  padding: 20px;
}

.card-wrapper {
  padding: 16px;
  margin: 16px 0;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.card__mask {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__mask__photo {
  width: 100%;
  background-color: rgba(25, 25, 25, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mc-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.mc-card__visual {
  border-right: 2px solid #ccc;
  padding-right: 10px;
  overflow: visible;
  position: relative;
}

.mc-card__visual::before {
  padding-top: 0;
}

.mc-card__content {
  padding-left: 20px;
  flex-grow: 1;
  text-align: left;
}

.mc-card__title {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  text-decoration: underline;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mc-card__body {
  text-align: left;
}

.mc-card__body__carac {
  font-weight: 400;
  font-size: 14px;
}

@media (max-width: 680px) {
  .product {
    padding: 20px;
  }

  .card-wrapper {
    margin: 8px;
    justify-content: center;
  }

  .card.vertical {
    flex-direction: column;
    align-items: flex-start;
  }

  .mc-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .mc-card__visual {
    width: 100%;
    height: auto;
    border-right: none;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .mc-card__content {
    padding-left: 10px;
    flex-grow: 1;
    width: 100%;
  }

  .mc-card__title {
    font-size: 14px;
    white-space: normal;
  }

  .mc-card__body__carac {
    font-size: 13px;
  }
}
</style>
