import { createRouter, createWebHistory } from 'vue-router';
import HomepageView from '../views/HomepageView.vue';
import NotFound from '../views/NotFound.vue';
import LegalNoticesView from "@/views/LegalNoticesView.vue";
import DataPrivacyView from "@/views/DataPrivacyView.vue";
import ProductIncidentsView from "@/views/ProductIncidentsView.vue";
import MakeClaimView from "@/views/MakeClaimView.vue";
import SearchView from "@/views/SearchView.vue";

const routes = [
	{ path: '/', name: 'home', component: HomepageView },
	{ path: '/ac/', redirect: '/' },
	{ path: '/fans/', redirect: '/' },
	{ path: '/404', name: 'NotFound', component: NotFound },
	{ path: '/:catchAll(.*)', redirect: '404' },
	{
		path: '/documents',
		name: 'documents',
		component: SearchView
	},{
		path: '/legal-notices',
		name: 'notices',
		component: LegalNoticesView
	},{
		path: '/data-privacy',
		name: 'privacy',
		component: DataPrivacyView
	},{
		path: '/product-incidents',
		name: 'productIncidents',
		component: ProductIncidentsView
	},
	{
		path: '/claim',
		name: 'claim',
		component: MakeClaimView
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
