export const OTHERS_LOGOS = [
	'LeroyMerlin',
	'SaintMaclou',
	'BricoCenter',
	'Bricoman',
	'Kbane',
	'Weldom',
];

export const LANGUAGES = {
	1: { value: 1, code: 'fr', text: 'Français', shortText: 'FR'},
	2: { value: 2, code: 'en', text: 'English', shortText: 'EN'},
	3: { value: 3, code: 'es', text: 'Español', shortText: 'ES'},
	4: { value: 4, text: 'Português', code: 'pt', shortText: 'PT'},
	5: { value: 5, text: 'Polski', code: 'pl', shortText: 'PL'},
	6: { value: 6, text: 'Română', code: 'ro', shortText: 'RO'},
	7: { value: 7, text: 'українська', code: 'uk', shortText: 'УК'},
	8: { value: 8, text: 'Ελληνικά', code: 'el', shortText: 'EΛ'},
	9: { value: 9, text: 'Italiano', code: 'it', shortText: 'IT'},
};

export const HOMEPAGE_LOGOS = [
	{ name: 'LOGO-MDH_Artens', alt: 'Artens Logo' },
	{ name: 'LOGO-MDH_Axton', alt: 'Axton Logo' },
	{ name: 'LOGO-MDH_Delinia', alt: 'Delinia Logo' },
	{ name: 'LOGO-MDH_Dexter', alt: 'Dexter Logo' },
	{ name: 'LOGO-MDH_Equation', alt: 'Equation Logo' },
	{ name: 'LOGO-MDH_Geolia', alt: 'Geolia Logo' },
	{ name: 'LOGO-MDH_Inspire', alt: 'Inspire Logo' },
	{ name: 'LOGO-MDH_Isoxa', alt: 'Isoxa Logo' },
	{ name: 'LOGO-MDH_Lexman', alt: 'Lexman Logo' },
	{ name: 'LOGO-MDH_Luxens', alt: 'Luxens Logo' },
	{ name: 'LOGO-MDH_Naterial', alt: 'Naterial Logo' },
	{ name: 'LOGO-MDH_Sensea', alt: 'Sensea Logo' },
	{ name: 'LOGO-MDH_Spaceo', alt: 'Spaceo Logo' },
	{ name: 'LOGO-MDH_Standers', alt: 'Standers Logo' },
	{ name: 'LOGO-MDH_Sterwins', alt: 'Sterwins Logo' },
  ];
