<template>
  <Header :sticky="true" :breadcrumbItems="breadcrumbItems" />
  <DataPrivacy class="container" />
  <Footer />
</template>

<script>

import DataPrivacy from "@/components/DataPrivacy.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    DataPrivacy,
    Footer
  },
  computed: {
    breadcrumbItems() {
      return [
        {text: this.$t('homePageLabel'), href: '/', active: true},
        {text: this.$t('dataPrivacyPolicy.name'), href: null}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  color: white;
}
</style>