<script setup>
	import { computed, ref } from 'vue';
	import { MCard } from '@mozaic-ds/vue-3';
	import { getImagePath } from '@/utils/image';
	import { ProductDetailsService } from '@/service/ProductDetailsService.js';

	const photoCardUrl = ref('');
	const isLoadingPhoto = ref(true);
  require('@/assets/NoPhotoAvailable.png')
	const props = defineProps({
		product: {
			type: Object,
			required: true,
		},
		layout: {
			type: String,
			default: null,
		},
	});

	const layoutClassCss = computed(() =>
		props.layout === 'horizontal' ? 'horizontal' : 'vertical',
	);

	const photoCard = computed(() => {
		return {
			productId: props.product.id,
		};
	});
	ProductDetailsService.getProductPhotoUrl(photoCard.value.productId)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((text) => {
        isLoadingPhoto.value = false;
        photoCardUrl.value = `${getImagePath(text)}?width=165`;
        props.product.photoUrl = photoCardUrl.value;
      })
      .catch(() => {
        isLoadingPhoto.value = false;
        photoCardUrl.value = `${getImagePath()}`;
        props.product.photoUrl = '';
      });
</script>
<template>
	<section :id="product.id" class="l-card">
		<MCard
			:title="product.name"
			:imgSrc="photoCardUrl"
			:class="layoutClassCss"
			:lazy="true"
			:fluid="true"
		>
			<template v-slot:default>
				<p class="l-card__text">
					{{ $t('GTIN') }} 
					<span v-for="(gtin, index) in product.gtins" :key="index">
					{{ gtin }}<span v-if="index < product.gtins.length - 1">, </span>
					</span>
				</p>
				<p class="l-card__text">{{ $t('ADEOKEY') }} {{ product.adeoKey }}</p>
				<p class="l-card__text">
					{{ $t('REFERENCE') }}
					<span v-for="(reference, index) in product.references" :key="index">
                  		{{ reference }}<span v-if="index < product.references.length - 1">, </span>
                	</span>
				</p>
			</template>
		</MCard>
	</section>
</template>

<style lang="scss">
	@import 'settings-tools/_all-settings';
	.l-card {
		position: relative;
		background-color: white;
		border-radius: 3px;
		padding: $mu100;
		&__text {
			@include set-font-scale('01', 's');
			color: $color-grey-400;
			text-align: start;
		}
		.mc-card__title {
			@include set-font-scale('06', 'm');
			height: 110px;
			text-align: start;
		}
	}
</style>
