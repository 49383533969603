export const getExtension = (fileName) => fileName.slice(fileName.lastIndexOf('.'));
export const getTranslatedKeyForDocument = (documentType) => {
	const allowedDocumentTypes = ['DOC', 'IMD', 'EPS', 'IMS', 'DOP', 'ERP', 'PAC', 'PLB', 'FDS', 'RIM', 'PND'];
	return allowedDocumentTypes.includes(documentType)
		? `documentTypeLabel.${documentType}`
		: 'documentTypeLabel.default';
};
export const getProperFileName = (file, files) => {
	return `${getTranslatedKeyForDocument(file.documentType)}-${this.productDetails.gtin}-${this.productDetails.adeoKey}-${this.getFileNumber(file, files)}`;
};
