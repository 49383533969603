<script setup>
import {onMounted} from "vue";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

onMounted(() => {
  scrollToTop();
});
</script>

<template>
<div class="form-container">
  <h1>
    <span>{{$t('legalNotices.title')}}</span>
  </h1>
  <h3>
    <span>{{$t('legalNotices.royalties.title')}}</span>
  </h3>
  <p>
    <span>{{$t('legalNotices.royalties.firstSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.royalties.secondSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.royalties.thirdSpan')}}</span>
  </p>
  <h3>
    <span>{{$t('legalNotices.trademarks.title')}}</span>
  </h3>
  <p>
    <span>{{$t('legalNotices.trademarks.firstSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.trademarks.secondSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.trademarks.thirdSpan')}}</span>
  </p>
  <h3>
    <span>{{$t('legalNotices.additionalInformation.title')}}</span>
  </h3>
  <p>
    <span>{{$t('legalNotices.additionalInformation.firstSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.additionalInformation.secondSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.additionalInformation.thirdSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.additionalInformation.fourthSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.additionalInformation.fifthSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.additionalInformation.sixthSpan')}}</span>
  </p>
  <h3>
    <span>{{$t('legalNotices.remarks.title')}}</span>
  </h3>
  <p>
    <span>{{$t('legalNotices.remarks.firstSpan')}}</span>
    <br/>
    <span>{{$t('legalNotices.remarks.secondSpan')}}</span>
  </p>
</div>
</template>

<style scoped lang="scss">
@import 'settings-tools/_all-settings';

.form-container {
  margin: 0 auto;
  max-width: 1040px;
  text-align: justify !important;
  padding: 0 3%;
  @include set-from-screen('m') {
    padding: 0;
  }
  margin-bottom: 3%;
}

h3 {
  margin: 2% 0;
}

</style>