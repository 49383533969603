<template>
  <div class="container">
    <Header :sticky="false" :breadcrumbItems="breadcrumbItems" />
    <div class="content">
      <ProductIncident/>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ProductIncident from "@/components/ProductIncidents.vue";

export default {
  components: {
    Header,
    ProductIncident,
    Footer
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: this.$t('homePageLabel'), href: '/', active: true},
        { text: this.$t('accidentOrSafetyProblemLabel'), href: null}
      ]
    }
  }
};
</script>


<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}
</style>
