<template>
  <div class="product-form-wrapper">
    <div v-if="!showSuccessMessage">
      <ProductDetailsCard :customTitle="customTitle"/>
      <slot name="form"></slot>
    </div>

    <div v-else class="success-message">
      <MNotification type="success" class="notification">
        <p>{{ $t('successfullFormSubmitionNotificationMessage') }}</p>
      </MNotification>
      <MButton :label="$t('backToHomePage')" @click="navigateHome" id="returnHome"/>
    </div>
  </div>
</template>

<script>
import { watch } from 'vue';
import ProductDetailsCard from '@/components/ProductDetailsCard.vue';
import MNotification from '@mozaic-ds/vue-3/src/components/notification/MNotification.vue';
import MButton from '@mozaic-ds/vue-3/src/components/button/MButton.vue';
import { useRouter } from 'vue-router';

export default {
  props: {
    showSuccessMessage: {
      type: Boolean,
      required: true,
    },
    customTitle: {
      type: String,
      default: null,
    }
  },
  setup(props) {


    const router = useRouter();

    const navigateHome = () => {
      router.push({ name: 'home' });
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    // Watch for changes in showSuccessMessage to trigger scroll to top
    watch(
      () => props.showSuccessMessage,
      (newValue) => {
        if (newValue) {
          scrollToTop();
        }
      }
    );

    return {
      navigateHome,
    };
  },
  components: {
    ProductDetailsCard,
    MButton,
    MNotification
  }
};
</script>

<style scoped>
.product-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 1040px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  margin-top: 20px;
}

.notification {
  margin-bottom: 20px;
}
</style>
