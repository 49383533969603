// @ts-ignore
import RequestUtils from '@/service/RequestUtils';

export const ProductDetailsService = {
	getProductsPageWithPaginationParams: async (q, from, size) => {
		const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/products?q=${q}&from=${from}&size=${size}`;
		return await RequestUtils.executeRequest(apiURL);
	},
	getProductDetails: async (adeoKey, gtins) => {
		const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/products/productDetails?adeoKey=${adeoKey}&gtins=${gtins}`;
		return await RequestUtils.executeRequest(apiURL);
	},

	getProductPhotoUrl: async (productId) => {
		const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/products/photoUrl?productId=${productId}`;
		return await RequestUtils.executeRequest(apiURL);
	},
};
