import RequestUtils from '@/service/RequestUtils';
import i18next from 'i18next';

export const CustomerRequestService = {

  missingDocumentRequest: async (formData, product) => {
    const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/customer-ticket/missing-document`;

    const payload = {
      adeoKey: product.adeoKey,
      gtins: product.gtins,
      supplierReferences: product.references,
      missingDocumentType: formData.document,
      name: formData.name,
      firstname: formData.firstName,
      email: formData.email,
      profile: formData.profile,
      country: formData.country,
    };

    const headers = {
      'Content-Type': 'application/json',
      'selectedLanguage': i18next.language,
      [process.env.VUE_APP_x-gateway-apikey]: process.env.VUE_APP_bR2P1YEYIkqAc8eQirjCCSnfU72JCE4P,
    };

    const options = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(apiURL, options);
  
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
      
      return response.ok;
    } catch (error) {
      throw new Error(`Failed to submit form: ${error.message}`);
    }
  },

  incidentReportRequest: async (formData, product, attachmentFile) => {
    const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/customer-ticket/incident-report`;

    const payload = new FormData();

    const incidentReportData = {
        adeoKey: product.adeoKey,
        gtins: product.gtins,
        supplierReferences: product.references,
        name: formData.name,
        firstname: formData.firstName,
        email: formData.email,
        country: formData.country,
        description: formData.description,
    };

    payload.append('incidentReportTicketFormDTO', JSON.stringify(incidentReportData));

    if (attachmentFile) {
      payload.append('attachment', attachmentFile);
    }

    const headers = {
      'selectedLanguage': i18next.language,
      [process.env.VUE_APP_x-gateway-apikey]: process.env.VUE_APP_bR2P1YEYIkqAc8eQirjCCSnfU72JCE4P
    };

    try {
      const response = await fetch(apiURL, {
        method: 'POST',
        headers,
        body: payload,
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      return response.ok;
    } catch (error) {
      throw new Error(`Failed to submit form: ${error.message}`);
    }
  },

  async getAllowedDocumentTypes(productId) {
    const apiURL =
        process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 + '/api/customer-ticket/missing-document/allowed-document-types?productId=' + productId;

    return await RequestUtils.executeRequest(apiURL);
  }
};
