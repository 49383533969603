<script>
import Header from "@/components/Header.vue";
import Search from "@/components/Search.vue";
import Footer from "@/components/Footer.vue";
import Homepage from "@/components/Homepage.vue";
import store from '@/store';
import ProductDetailsLayer from "@/components/ProductDetailsLayer.vue";
import ProductAndFormWrapper from "@/components/ProductAndFormWrapper.vue";
import MissingDocumentForm from "@/components/MissingDocumentForm.vue";
import {ref} from 'vue';

export default {

  setup() {
    const showSuccessMessage = ref(false);

    function handleFormSubmitted() {
      showSuccessMessage.value = true;
    }

    return {
      showSuccessMessage,
      handleFormSubmitted,
    };
  },
  components: {
    ProductAndFormWrapper,
    ProductDetailsLayer,
    Homepage,
    Header,
    Search,
    Footer,
    MissingDocumentForm
  },
  computed: {
    store() {
      return store
    },
    breadcrumbItems() {
      if (store.getters.isDisplaySearch) {
        return [
          {text: this.$t('homePageLabel'), href: '/', active: true},
          {text: this.$t('searchDocumentPageLabel'), href: null}
        ]
      } else {
        return [
          {text: this.$t('homePageLabel'), href: '/', active: true},
          {text: this.$t('missingDocumentPageLabel'), href: null}
        ]
      }
    }
  }
};
</script>

<template>
  <div class="page-container">
    <Header :sticky="true" :breadcrumbItems="breadcrumbItems"/>
    <main class="main-content">
      <Search v-if="store.getters.isDisplaySearch"/>
      <ProductAndFormWrapper v-else  :showSuccessMessage="showSuccessMessage">
        <template #form>
          <MissingDocumentForm @form-submitted="handleFormSubmitted"/>
        </template>
      </ProductAndFormWrapper>
    </main>
    <Footer/>
  </div>
</template>

<style scoped lang="scss">
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}
</style>
