<template>
  <div class="l-concernBreakdown" v-if="!concernBreakdownHidden">
    <div class="l-concernBreakdown__container">
      <h2>{{ $t('concernBreakdown.title') }}</h2>
      <div class="l-concernBreakdown__redirection">
        <MButton :label="$t('concernBreakdown.yes')" @click="displayBreakdown" id="yes" theme="bordered-neutral"/>
        <MButton :label="$t('concernBreakdown.no')" @click="displayAccidentSearch" id="no" theme="bordered-neutral"/>
      </div>
    </div>
  </div>
  <div class="l-breakdown" v-if="!breakdownHidden">
    <MakeClaim/>
  </div>
  <div class="l-accidentSearch" v-if="!accidentSearchHidden">
    <Search :customSearchAction="customAction"
          :customTitle="customTitle"
          :customDescription="customDescription" />
  </div>
  <div class="l-form" v-if="!formHidden">
    <ProductAndFormWrapper class="container" :customTitle="customTitle" :showSuccessMessage="showSuccessMessage">
      <template #form>
        <ProductIncidentForm @form-submitted="handleFormSubmitted" />
      </template>
    </ProductAndFormWrapper>
  </div>
</template>

<script>
import MakeClaim from '@/components/MakeClaim.vue';
import Search from "@/components/Search.vue";
import ProductIncidentForm from "@/components/ProductIncidentForm";
import ProductAndFormWrapper from '@/components/ProductAndFormWrapper.vue';
import {ref} from "vue";

export default {
  setup() {
    const showSuccessMessage = ref(false);

    function handleFormSubmitted() {
      showSuccessMessage.value = true;
    }

    return {
      showSuccessMessage,
      handleFormSubmitted,
    };
  },
  components: {
    Search,
    MakeClaim,
    ProductIncidentForm,
    ProductAndFormWrapper
    },
  data() {
    return {
      concernBreakdownHidden: false,
      breakdownHidden: true,
      accidentSearchHidden: true,
      formHidden: true,
      customSearchAction: null,
      customTitle : 'search.title.safetyProblem',
      customDescription: ' ',
    };
  },

  methods: {
    displayAccidentSearch() {
      this.concernBreakdownHidden = !this.concernBreakdownHidden;
      this.accidentSearchHidden = !this.accidentSearchHidden;
      this.customSearchAction = this.customAction;
      this.scrollToTop()
    },

    displayBreakdown() {
      this.concernBreakdownHidden = !this.concernBreakdownHidden;
      this.breakdownHidden = !this.breakdownHidden;
      this.scrollToTop()
    },

    customAction() {
      this.accidentSearchHidden = !this.accidentSearchHidden;
      this.scrollToTop()
      this.formHidden = !this.formHidden;
    },
    scrollToTop (){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}

</script>

<style scoped lang="scss">
@import 'settings-tools/_all-settings';

.l-concernBreakdown {
  font-family: 'Roboto', sans-serif;
  max-width: 1040px;
  margin: 0 auto;

  &__container {
    margin: 0 $mu100;
    background-color: white;
    padding: $mu100;
    border-radius: 8px;
    color: $color-grey-900;
    text-align: justify;
    @include set-from-screen('m') {
      padding: $mu100 0 $mu200 $mu400;
      margin: 0;
      text-align: left;
    }
  }

  &__redirection {
    display: grid;
    @include set-from-screen('m') {
      display: block;
    }
  }

  h1 {
    @include set-font-scale('09', 'm');
    margin-bottom: $mu100;
    @include set-from-screen('m') {
      margin-bottom: $mu250;
    }
  }

  #no {
    margin-top: $mu150;
    @include set-from-screen('m') {
      margin-left: $mu100;
      margin-top: 0;
    }
  }
}
</style>
