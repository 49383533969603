import {NO_PHOTO_AVAILABLE_PATH} from "@/constants/image";

export default class PhotoService {
    getImagePath(photoUrl) {
        return photoUrl && photoUrl !== '' ? photoUrl + '?width=165' : `${NO_PHOTO_AVAILABLE_PATH}`;
    }

    getLogoImagePath(name) {
        return require('../assets/logos/brandLogos/' + name + '.png');
    }
}
